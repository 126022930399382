export function ReferralData() {
    return {
        "katkin": {
            Company: "Katkin",
            CompanyDescription: "Fresh cat food, delivered",
            ReferralLink: "https://katkin.mention-me.com/m/ol/bv0gs-74270a779d",
            ReferralCode: "bv0gs-74270a779d",
            YouGet: "50% off",
            IGet: "50% off"
        },
        "manypets": {
            Company: "ManyPets",
            CompanyDescription: "Pet Insurance",
            ReferralLink: "https://mention-me.com/m/ol/ix9qj-33266c6d7a",
            ReferralCode: "ix9qj-33266c6d7a",
            YouGet: "£20 Amazon.co.uk Gift Card",
            IGet: "£20 Amazon.co.uk Gift Card"
        },
        "republicofcats": {
            Company: "Republic of Cats",
            CompanyDescription: "Tailored cat food, delivered",
            ReferralLink: "http://republicofcats.refr.cc/dyanar",
            ReferralCode: "dyanar",
            YouGet: "a taster box tailored to your cat for £2.50",
            IGet: "£10 credit"
        },
        "pactcoffee": {
            Company: "Pact Coffee",
            CompanyDescription: "Coffee subscription service",
            ReferralLink: "https://www.pactcoffee.com/coffee-plans/signup?voucher=DYANA-6F8F99",
            ReferralCode: "DYANA-6F8F99",
            YouGet: "£5 off",
            IGet: "£5 credit"
        },
        "souschef": {
            Company: "Sous Chef",
            CompanyDescription: "Ingredients and cookware",
            ReferralLink: "https://i.refs.cc/lIqnmZXa?smile_ref=eyJzbWlsZV9zb3VyY2UiOiJzbWlsZV91aSIsInNtaWxlX21lZGl1bSI6IiIsInNtaWxlX2NhbXBhaWduIjoicmVmZXJyYWxfcHJvZ3JhbSIsInNtaWxlX2N1c3RvbWVyX2lkIjoxMzc3NTg5MjEzfQ%3D%3D",
            ReferralCode: "",
            YouGet: "£10 off",
            IGet: "£10 credit"
        },
        "ubereats": {
            Company: "Uber Eats",
            CompanyDescription: "Food delivery",
            ReferralLink: "https://ubereats.com/feed?promoCode=eats-dyanar42ue",
            ReferralCode: "eats-dyanar42ue",
            YouGet: "£3 off a £20 order",
            IGet: "£3 off a £20 order"
        },
        "quidco": {
            Company: "Quidco",
            CompanyDescription: "Cashback site",
            ReferralLink: "https://www.quidco.com/raf/3214904/",
            ReferralCode: "3214904",
            YouGet: "£1 after earning £5 in Cashback",
            IGet: "£10"
        },
        "suttons": {
            Company: "Suttons",
            CompanyDescription: "Seeds, plants, and gardening equipment",
            ReferralLink: "https://suttons.mention-me.com/m/ol/zz7ew-dyana-rose",
            ReferralCode: "zz7ew-dyana-rose",
            YouGet: "20% off",
            IGet: "20% off"
        },
        "lyftusa": {
            Company: "Lyft USA",
            CompanyDescription: "Ride-hailing service",
            ReferralLink: "https://ride.lyft.com/invite/DYANA32090",
            ReferralCode: "DYANA32090",
            YouGet: "$10 credit",
            IGet: "$10 credit"
        },
        "hrblockexpat": {
            Company: "H&R Block Expat",
            CompanyDescription: "US tax prep for expats",
            ReferralLink: "https://expatonline.hrblock.com/wizard?ref=d41d0d0be54c19a994fbe70715433c2c",
            ReferralCode: "d41d0d0be54c19a994fbe70715433c2c",
            YouGet: "$20 off",
            IGet: "$20 credit"
        },
        "trustedhousesitters": {
            Company: "Trusted Housesitters",
            CompanyDescription: "Homestays around the world, in exchange for caring for adorable pets",
            ReferralLink: "https://www.trustedhousesitters.com/refer/RAF644472/?utm_source=copy-link&utm_medium=refer-a-friend&utm_campaign=refer-a-friend",
            ReferralCode: "RAF644472",
            YouGet: "25% off",
            IGet: "2 months free"
        },
        "costacoffee": {
            Company: "Costa Coffee",
            CompanyDescription: "Coffee Shop",
            ReferralLink: "https://www.costa.co.uk/costa-club?raf_code=0VWJN",
            ReferralCode: "0VWJN",
            YouGet: "6 beans (purchase stamps)",
            IGet: "5 beans"
        },
        "caffenero": {
            Company: "Caffe Nero",
            CompanyDescription: "Coffee Shop",
            ReferralLink: "https://nero.app.link/pOVIEjIqGzb",
            ReferralCode: "",
            YouGet: "free coffee after purchase",
            IGet: "free coffee"
        },
        "myurbanjungle": {
            Company: "My Urban Jungle",
            CompanyDescription: "Buildings and Contents Home Insurance",
            ReferralLink: "https://myurbanjungle.com/dyana-rose-healthful-olm",
            ReferralCode: "dyana-rose-healthful-olm",
            YouGet: "£20 off",
            IGet: "£20 off"
        },
        "smol": {
            Company: "smol",
            CompanyDescription: "Subscription cleaning products",
            ReferralLink: "https://smol.refr.cc/dyanar",
            ReferralCode: "dyanar",
            YouGet: "free trial",
            IGet: "£6.60 discount"
        },
        "neat": {
            Company: "neat",
            CompanyDescription: "Subscription cleaning products",
            ReferralLink: "https://neatclean.refr.cc/dyanar",
            ReferralCode: "dyanar",
            YouGet: "15% off your first order",
            IGet: "2 free refills"
        },
        "animalfriends": {
            Company: "Animal Friends",
            CompanyDescription: "Pet Insurance",
            ReferralLink: "https://animalfriends.mention-me.com/m/ol/qj8cl-dyana-rose",
            ReferralCode: "qj8cl-dyana-rose",
            YouGet: "£20 voucher",
            IGet: "£20 voucher"
        },
        "zooplus": {
            Company: "Zooplus",
            CompanyDescription: "Online Pet Shop, Pet Food & Pet Accessories.",
            ReferralLink: "https://www.zooplus.com/invitations/BLsd63aI",
            ReferralCode: "BLsd63aI",
            YouGet: "10% welcome coupon",
            IGet: "350 points"
        },
        "birdandblend": {
            Company: "Bird and Blend",
            CompanyDescription: "An eco conscious, independent, people-focused, award winning Tea Mixology Company on a mission to spread happiness & reimagine tea! ",
            ReferralLink: "https://mention-me.com/m/ol/rl0qv-dyana-rose",
            ReferralCode: "rl0qv-dyana-rose",
            YouGet: "£5 off",
            IGet: "£5 off"
        },
        "hpinstantink": {
            Company: "HP Instant Ink",
            CompanyDescription: "Printer ink subscription service.",
            ReferralLink: "http://try.hpinstantink.com/rSBjG",
            ReferralCode: "rSBjG",
            YouGet: "1 month trial",
            IGet: "1 month trial"
        },
        "lesmillsondemand": {
            Company: "Les Mills On Demand",
            CompanyDescription: "Unlimited access to thousands of workouts and all LES MILLS™ programs, including the iconic BODYPUMP™ workout",
            ReferralLink: "https://try.lesmillsondemand.com/refer/?grsf=dyana-c3zyg3",
            ReferralCode: "dyana-c3zyg3",
            YouGet: "30 day trial",
            IGet: "30 days free"
        },
        "cultbeauty": {
            Company: "Cult Beauty",
            CompanyDescription: "The destination for the very best in beauty, we trawl the globe to create a curated ‘Hall of Fame’. From bestselling skin care to make up must-haves.",
            ReferralLink: "https://www.cultbeauty.co.uk/referrals.list?applyCode=DYANA-R4",
            ReferralCode: "DYANA-R4",
            YouGet: "£5 off your first order over £25",
            IGet: "£5 off"
        },
        "theperfumestudio": {
            Company: "The Perfume Studio",
            CompanyDescription: "Bespoke fragrances and group fragrance making experiences.",
            ReferralLink: "https://www.theperfumestudio.com/r/74581/",
            ReferralCode: "74581",
            YouGet: "??",
            IGet: "rewards points"
        },
        "octopusenergy": {
            Company: "Octopus Energy",
            CompanyDescription: "Powering 5 million+ UK homes and businesses with award-winning 100% green electricity today. And building the sustainable energy system of tomorrow.",
            ReferralLink: "https://share.octopus.energy/red-dawn-633",
            ReferralCode: "red-dawn-633",
            YouGet: "£50 for home or £100 for business",
            IGet: "£50 for home or £100 for business"
        },
        "potr": {
            Company: "Potr",
            CompanyDescription: "Eco-friendly, flat pack plant pots and vases.",
            ReferralLink: "https://talkable.com/x/g6cpQ9",
            ReferralCode: "g6cpQ9",
            YouGet: "£10 off",
            IGet: "£10 off"
        }
    }
}

export function ReferralSections() {
    return [
        {
            Title: "Pets 🐶",
            Referrals: [
                "manypets",
                "animalfriends",
                "katkin",
                "republicofcats",
                "zooplus",
                "trustedhousesitters"
            ]
        },
        {
            Title: "Household 🏡",
            Referrals: [
                "smol",
                "neat",
                "hpinstantink",
                "octopusenergy",
                "potr"
            ]
        },
        {
            Title: "Food & Drink ☕",
            Referrals: [
                "pactcoffee",
                "souschef",
                "ubereats",
                "costacoffee",
                "caffenero",
                "birdandblend"
            ]
        },
        {
            Title: "Wellbeing & Fitness 👟",
            Referrals: [
                "lesmillsondemand",
                "cultbeauty",
                "theperfumestudio"
            ]
        },
        {
            Title: "Shopping & Entertainment 💷",
            Referrals: [
                "humblebundle",
                "quidco",
                "suttons",
                "souschef",
                "birdandblend",
                "hpinstantink",
                "potr"
            ]
        },
        {
            Title: "Travel 🚗",
            Referrals: [
                "lyftusa",
                "trustedhousesitters"
            ]
        },
        {
            Title: "Finance & Insurance 🏛️",
            Referrals: [
                "hrblockexpat",
                "manypets",
                "animalfriends",
                "myurbanjungle"
            ]
        }
    ]
}
